.home-project-image{
    width: 25vw;
    height: 25vw;
    /* border-radius: 8px; */
}
.white {
    color: white !important;
}
.filter-invert {
    filter: invert(1);
}
/* BASIC LOGO */
.home-basic-logo {
    filter: invert(1);
    width: 50vw;
}
/* BASIC LOGO ENDS */

.padding-5em {
    padding: 5em 0em !important;
}
.padding-5-5em {
    padding: 5.5em 0em !important;
}
.hover-text {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.container-hover-image {
    position: relative;
}
.container-hover-image:hover .home-project-image {
    -webkit-filter: opacity(0.20);
    filter: opacity(0.20);
    transform: scale(1.05);
    cursor: pointer;
}
.container-hover-image:hover .hover-text {
    opacity: 1;
}

.portrait-image {
    border-radius: 50%;
    width: 25vh;
    height: 25vh;
}
.portrait-image:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.portrait-main-image {
    border-radius: 50%;
    width: 25vh;
    height: 25vh;
}
.portrait-main-image:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.portrait-main-branch {
    border-radius: 50%;
    width: 20vw;
    height: 20vw;
    object-fit: fill;
}
.portrait-main-branch:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.epyco-footer {
    margin-bottom: -20px !important;
}
.background-white {
    background-color: white !important;
    color: black !important;
}
/* RED BANNER */
.red-banner {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        /* rgba(0, 0, 0, 0.9), */
        rgba(161, 44, 21, 0.7),
        rgba(0, 0, 0, 1)
      ), 
      url('../images/F5.webp');

    background-size: cover;
    height: 50vh;
    padding: 60px;
    text-align: top;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.red-banner:hover {
    /* -webkit-transform: scale(1.8) rotate(0.01deg);
    transform: scale(1.8) rotate(0.01deg); */
}
.red-banner-img {
    display: block;
    width: 100%;
}
.red-banner-caption {
    font-family: 'Times New Roman', sans-serif;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5rem;
    transform: translate(-50%, -50%);
    /* filter: brightness(100%) */
}
/* RED BANNER END */

/* BANNER  */
.banner {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        /* rgba(0, 0, 0, 0.9), */
        rgba(52, 62, 86, 0.7),
        rgba(0, 0, 0, 1)
      ), 
      url('../images/HomeS1.jpg');

    background-size: cover;
    height: 100vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner:hover {
    /* -webkit-transform: scale(1.8) rotate(0.01deg);
    transform: scale(1.8) rotate(0.01deg); */
}
.banner-img {
    display: block;
    width: 100%;
}
.banner-caption {
    font-family: 'Times New Roman', sans-serif;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5rem;
    transform: translate(-50%, -50%);
    /* filter: brightness(100%) */
}
.margin-0 {
    margin: 0 !important;
}
/* BANNER */
/* OVERLAY */
.overlay-background {
    height: 12vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 0; /* Sit on top */
    left: 0;
    top: 0;
    /* background-color: rgb(0,0,0, 0.45);  */
    background-color: rgba(50,50,50, 0.98); 
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s;
    opacity: 0.7; 
    filter: blur(1px)
}
.overlay {
    height: 12vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    /* background-color: rgb(0,0,0, 0.45);  */
    background-color: rgba(50,50,50, 0.98); 
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s;
    opacity: 0.7; 
}
.overlay-content {
    position: relative;
    /* top: 25%;  */
    width: 100vw; /* 100% width */
    text-align: center; /* Centered text/links */
    margin: 3vh 0%;
}
.header-logo {
    width: 4vw;
    height: 7vh;
    transition: 1s ease;
    /* transition-delay: 1s; */
    /* transition-delay: 3s; */
    /* -webkit-transform: rotate(270deg);
    -webkit-animation-duration: 2s; */
}
.header-logo-rotated {
    width: 4vw;
    height: 7vh;
    transform: rotate(270deg);
    transition: 1s ease;
    transition-delay: 1s;
    /* transition-delay: 3s; 
    -webkit-transform: rotate(270deg);
    animation-duration: 3s;
    -webkit-animation-duration: 2s; */
}
/* OVERLAY */
/* BOTTOM RIGHT OVERLAY */
.bottom-overlay {
    /* height: 12vh; */
    /* width: 100vw; */
    /* margin-right: 2vw; */
    /* margin-bottom: 4vh; */
    padding: 2vw 3vh;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    right: 0;
    bottom: 0;
    /* background-color: rgb(0,0,0, 0.3);  */
    /* background-color: rgba(100,0,0, 1);  */
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s; 
    /* border-radius: 50%;
    height: 5vh;
    width: 5vh; */
}
.bottom-overlay-conent {
    position: relative;
    /* top: 25%;  */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    /* margin-bottom: 2vh; */
    /* margin-right: 5%; */
}
.bottom-overlay-icon {
    position: relative;
    /* transform: translateY(-1vh); */
    transition: 2s all;
    text-align: center;
    margin-bottom: 0.5vh;
    /* font-size: 3em; */
    /* width: 2vh;
    height: 2vh; */
}
.icon-three-em {
    font-size: 3em !important;
}
/* BOTTOM RIGHT OVERLAY */

/* PROJECT */
.project-main-font {
    font-size: 1.88em;
}
/* PROJECT */
.black {
    color: black !important;
}
.background-black {
    background-color: black !important;
}
.white-link {
    color: white !important;
}
.white-link:visited {
    color: white !important;
}
.white-link:hover {
    color: white !important;
}
.white-link:active {
    color: white !important;
}

/* CAROUSEL */
.epyco-carousel-image {
    height: 100vh;
    width: 100vw;
    filter: brightness(0.35);
}
.epyco-carousel-image-fading {
    height: 100vh;
    width: 100vw;
    filter: contrast(0.4);
    /* background: linear-gradient(to bottom, rgba(0,100,20, 0.9), rgba(160,20,20, 1.0)); */
}
.banner-carousel-1 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url('../images/F1.webp');

    background-size: contain;
    background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-2 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url('../images/F2.webp');

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-3 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url('../images/F3.webp');

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-4 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
         rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url('../images/F4.webp');

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-5 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url('../images/F5.webp');

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
/* CAROUSEL ENDS */
.hr-mw {
    max-width: 80vw !important;
    margin-left: auto!important;
    margin-right: auto!important;
}
.hr-margin {
    margin: 2vh 0px !important;
}
.footer-padding {
    padding: 2.9em 0em !important;
}
.grid-shadow {
    box-shadow: -1px 0 0 0 rgba(255,255,255,0.5) !important;
}
.no-grid-shadow {
    box-shadow: -1px 0 0 0 rgba(255,255,255,0) !important;;
}
/* Footer Min-Max */
.left-column {

}
.middle-column {
    text-align: center !important;
}
.right-column {
    text-align: left !important;
    padding-left: 9vw !important;
}
.footer-header {
    text-align: left;
    font-size: 1.371429rem; 
    width: 91%
}
.width-100 {
    width: 100vw !important;
}
.mobile-icon {
    text-align: left;
    padding: 0% 4%;
}

/* Footer Min-Max Ends*/
/* Messenger Playground */
/* .iframe {
    margin: 19vh 2.2vh !important;
} */
/* Messenger Playground Ends*/

@media only screen and (max-width: 760px) {
    p {
        font-size:  1.05em !important;
    }
    li {
        font-size: 0.95em !important;
    }
    h1, h2, h3, h4 {
        font-size: 1.30em !important;
    }
    .header-logo {
        width: 7vw;
        height: 6vh;
    }
    .banner-carousel-1, .banner-carousel-2, .banner-carousel-3, .banner-carousel-4, .banner-carousel-5 {
        background-size: cover !important;
        height: 60vh;
    }
    .portrait-main-branch {
        width: 45vw;
        height: 45vw;
    }
    .home-project-image {
        width: 67vw;
        height: 67vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        text-align: center;
    }
    .footer-header {
        text-align: center;
    }
    .footer-list { 
        text-align: center !important;
    }
    .banner-caption {
        transform: translate(-50%, -160%);
    }
}
@media only screen and (min-width: 761px) and (max-width: 1223px) and (orientation: landscape) {
    p {
        font-size:  1em !important;
    }
    li {
        font-size:  0.85em !important;
    }
    h1, h2, h3 {
        font-size: 1.45em !important;
    }
    h4 {
        font-size: 0.85em !important;
    }

}
@media only screen and (min-width: 1225px) and (max-width: 1600px) {
    p {
        font-size:  1.15em !important;
    }
    h1 {
        font-size: 1.50em !important;
    }
    h2 {
        font-size: 1.3em !important;
    }
    h3 {
        font-size: 1.25em !important;
    }
    h4 {
        font-size: 1.10em !important;
    }
    span {
        font-size: 0.90em !important;
    }
    /* .header-logo {
        width: 7vw;
        height: 6vh;
    }
    .banner-carousel-1, .banner-carousel-2, .banner-carousel-3, .banner-carousel-4, .banner-carousel-5 {
        background-size: cover !important;
        height: 60vh;
    }
    .portrait-main-branch {
        width: 45vw;
        height: 45vw;
    }
    .home-project-image {
        width: 67vw;
        height: 67vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        text-align: center;
    }
    .footer-header {
        text-align: center;
    }
    .footer-list { 
        text-align: center !important;
    }
    .banner-caption {
        transform: translate(-50%, -160%);
    } */
}