/* Bottom Overlay Spring */
.simple-trans-main {
    height: 100vh;
}
.simple-trans-main > div {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 25em;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }
  
/* Bottom Overlay Spring END*/
/* Keyframes */
/* .keyframes {
    height: 100vh;
}
.keyframes > div {
    height: 100vh;
} */
.bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }
/* Keyframes END*/