.title-h3 {
    font-size: xx-large;
    color: white;
    background: url('../images/breakfast.jpg')
}
.main-container {
    min-height: 70vh;
}
.image-div {
    padding: 5%;
}
.small-image {
    height: 40vh;
}
.enlarged-image {

}
.product-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.product-detail-container {
    margin: 5%;
    background: aliceblue;
    border-radius: 1vh;
    padding: 1%;
}
.product-detail-image {
    height: 25vh;
    width: 25vh;
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}
.product-detail-image:hover {
    -webkit-transform: scale(1.2) rotate(0.01deg);
    transform: scale(1.2) rotate(0.01deg);
}
.products {
    /* border: 1px solid; */
    background-color: white;
    background-image: url('../images/dark-wood.png');
    margin-bottom: 5em;
    border-radius: 1vh;
}

.product-image {
    -webkit-transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
}

.product-image:hover {
    -webkit-transform: scale(1.2) rotate(0.01deg);
    transform: scale(1.2) rotate(0.01deg);
}

.huge {
    font-size: 2rem;
    color: #162E2C;
    font-weight: bolder;
}

.background {
    background-color: #2E2016;
    /* background-color: #00325e; */
    /* background-color: white; */
    /* background-image: linear-gradient(
        rgba(26, 12, 2, 0.5),
        rgba(0, 0, 0, 0.5)
      ), url('../images/dark-wood.png'); */
    background-image: url('../images/dark-wood.png');

}

.hidden-mobile {
    display: block;
}

@media only screen and (max-width: 1000px) {
    .hidden-mobile {
        display: none !important;
    }
}