/* -- code to use Gotham font-family in theme -- */
/* @font-face {
    font-family: 'Gotham';
    src: 	url('{{ "Gotham-Bold.eot" | asset_url }}');
    src: 	url('{{ "Gotham-Bold.eot" | asset_url }}#iefix') format("embedded-opentype"),
            url('{{ "GothamBold.woff" | asset_url }}') format("woff"),
      url('{{ "GothamBold.woff2" | asset_url }}') format("woff2"),
           url('{{ "GothamBold.svg" | asset_url }}#GothamBold') format("svg");
          font-weight: bold;
          font-style: normal;
  } */
  /* - end - */
  /* -- Pipeline override custom font: Headings -- */
.title,h1,.h1,h2,.h2,h3,.h3,h4.home__subtitle,h5.home--uppercase,.sky-pilot-access-denied h5,.sky-pilot-access-denied .h5,h4,.h4,h5,.h5,h6,.h6,.header-logo,.home__title,.title--flex,.product-form label,.password__hero {
    font-family: 'Gotham', sans-serif !important;
 }
 /* - end - */
 /* -- Pipeline override custom font: body font -- */
.body, .h1--body, .h2--body, .h3--body, .h4--body, .h5--body, .h6--body, p {
    font-family: 'Gotham', sans-serif !important;
}
/* - end - */
/* -- Pipeline override custom font: accent - Subheadings and buttons -- */
.accent, .uppercase, .nav--mobile .main-menu li a.nav-link, .nav--desktop .main-menu>li>a, .nav--desktop .grandparent .parent .child a, .nav--desktop .grandparent>.main-menu-dropdown>ul>.parent>a, .nav--desktop .grandparent>.main-menu-dropdown>ul>.child>a, .nav--desktop .main-menu>.parent>.main-menu-dropdown, .footer-social, .footer-payment, .sticker, .share__link, .sidebar__item a, .selector-wrapper label, .quantity-selector__wrapper label, .blog__tag, .item__vendor, .product__add__message, .h1--accent, .h2--accent, .h3--accent, .h4--accent, .h5--accent, .home__subtitle, .h6--accent, .footer-secondary, .footer-quicklinks, .breadcrumb{
    font-family: 'Gotham', sans-serif !important;
}
/* - end - */