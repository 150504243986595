.divImgAvatar {
    border-radius: 50%;
    /* background-color: black; */
    margin-top: 5%;
}
.margin-0 {
  margin: 0 !important;
}
.cart-image {
  height: 10vh;
  width: 5vw;
}

.margin-l-r-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cart-side-h3 {
  line-height: 4vh;
}

.imgAvatar {
    margin-right: .25em;
    display: inline-block;
    width: 5em;
    height: 5em;
    border-radius: 500rem
}

.amountButton {
    margin: 0% 4% !important;
}

.cardBorder { 
    padding: 2% 0% 2% 0%; 
    border: 0.5px solid #888888;
    border-radius: 5%;
    /* box-shadow: 5px 10px 18px #888888; */
    box-shadow: 0px 2px 2px #888888;
    transition: transform .1.5s;
    /* background-image: url("../logo.svg"); */
    background-repeat: no-repeat;
    /* transform-style: preserve-3d; */
}

.cardBorder:hover {
    transform: scale(1.05);
    /* transform: rotateY(180deg); */
}

.margin-bottom-75 {
  margin-bottom: 55px;
}

.hidden.menu {
    display: none;
  }

  .masthead.segment {
    min-height: 700px;
    padding: 1em 0em;
  }
  .masthead .logo.item img {
    margin-right: 1em;
  }
  .masthead .ui.menu .ui.button {
    margin-left: 0.5em;
  }
  .masthead h1.ui.header {
    margin-top: 3em;
    margin-bottom: 0em;
    font-size: 4em;
    font-weight: normal;
  }
  .masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
  }

  .ui.vertical.stripe {
    padding: 8em 0em;
  }
  .ui.vertical.stripe h3 {
    font-size: 2em;
  }
  .ui.vertical.stripe .button + h3,
  .ui.vertical.stripe p + h3 {
    margin-top: 3em;
  }
  .ui.vertical.stripe .floated.image {
    clear: both;
  }
  .ui.vertical.stripe p {
    font-size: 1.33em;
  }
  .ui.vertical.stripe .horizontal.divider {
    margin: 3em 0em;
  }

  .quote.stripe.segment {
    padding: 0em;
  }
  .quote.stripe.segment .grid .column {
    padding-top: 5em;
    padding-bottom: 5em;
  }

  .footer.segment {
    padding: 5em 0em;
  }

  .secondary.pointing.menu .toc.item {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    .hidden-mobile {
      display: block;
  }
  
    .ui.fixed.menu {
      display: none !important;
    }
    .secondary.pointing.menu .item,
    .secondary.pointing.menu .menu {
      display: none;
    }
    .secondary.pointing.menu .toc.item {
      display: block;
    }
    .masthead.segment {
      min-height: 350px;
    }
    .masthead h1.ui.header {
      font-size: 2em;
      margin-top: 1.5em;
    }
    .masthead h2 {
      margin-top: 0.5em;
      font-size: 1.5em;
    }
  }