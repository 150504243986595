/* The Modal (background) */
.modal {
    /* display: none;  */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .round-border {
    border-radius: 4vh;
  }
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    /* margin: 15% auto;      */
    padding: 5vh;
    border: 1px solid #888;
    width: 30%; 
    height: 70%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: black; */
    border-radius: 4vh;
  }

  .modal-field {
    margin: 0 0 1em;
  }

  .modal-close {
    position: absolute;
    right: 0;
    margin: 3vh 3vh 0vh 0vh !important;
    top: 0;
  }

  .modal-buttons {
   margin-top: 3vh;
   text-align: center; 
  }

  .modal-header {
      margin-bottom: 5vh;
      text-align: center;
      font-size: large;
  }

  .modal-logo {
      width: 10vh;
      height: 10vh;
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 1vh;
      margin-bottom: 1em;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 700px) {
    .modal-content {
      font-size: small;
      width: 85%;
    }

    .modal-logo {
      width: 7vh;
      height: 7vh;
    }

    .modal-close {
      position: absolute;
      right: 0;
      margin: 2vh 2vh 0vh 0vh !important;
      top: 0;
      font-size: smaller;
      width: 5vh;
      height: 4vh;
      padding: 0 !important;
    }
  }