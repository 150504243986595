body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.thick {
    font-weight: bolder !important;
}
.cursor-pointer {
    cursor: pointer;
}

.font-bolder {
    font-weight: bolder;
}

.font-small { 
    font-size: small;
}
.padding-2 {
    padding: 2%;
}
.padding-5 {
    padding: 5% !important;
}
.margin-5 {
    margin: 5%;
}
.margin-top-2 {
    margin-top: 2%;
}
.margin-bottom-2 {
    margin-bottom: 2% !important;
}

.carousel-img {
    height: 50vh;
    width: 100vw;
}
.carousel-caption-div {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: rgba(0, 0, 0, 0.65)
}
.carousel-caption-text {
    margin: 0 0 2vh 3vh;
    bottom: 0;
    left: 0;
    position: absolute;
    color: white;
    /* position: relative; */
    line-height: 10vh;
    z-index: 0;
}
.text-center {
    text-align: center;
}
.side-shopping-cart-title {
    margin-top: 1vh;
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}
.home-project-image{
    width: 25vw;
    height: 25vw;
    /* border-radius: 8px; */
}
.white {
    color: white !important;
}
.filter-invert {
    -webkit-filter: invert(1);
            filter: invert(1);
}
/* BASIC LOGO */
.home-basic-logo {
    -webkit-filter: invert(1);
            filter: invert(1);
    width: 50vw;
}
/* BASIC LOGO ENDS */

.padding-5em {
    padding: 5em 0em !important;
}
.padding-5-5em {
    padding: 5.5em 0em !important;
}
.hover-text {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.container-hover-image {
    position: relative;
}
.container-hover-image:hover .home-project-image {
    -webkit-filter: opacity(0.20);
    filter: opacity(0.20);
    transform: scale(1.05);
    cursor: pointer;
}
.container-hover-image:hover .hover-text {
    opacity: 1;
}

.portrait-image {
    border-radius: 50%;
    width: 25vh;
    height: 25vh;
}
.portrait-image:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.portrait-main-image {
    border-radius: 50%;
    width: 25vh;
    height: 25vh;
}
.portrait-main-image:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.portrait-main-branch {
    border-radius: 50%;
    width: 20vw;
    height: 20vw;
    object-fit: fill;
}
.portrait-main-branch:hover {
    transform: scale(1.1);
    cursor: pointer;
}
.epyco-footer {
    margin-bottom: -20px !important;
}
.background-white {
    background-color: white !important;
    color: black !important;
}
/* RED BANNER */
.red-banner {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        
        rgba(161, 44, 21, 0.7),
        rgba(0, 0, 0, 1)
      ), 
      url(/static/media/F5.fc873cbf.webp);

    background-size: cover;
    height: 50vh;
    padding: 60px;
    text-align: top;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.red-banner:hover {
    /* -webkit-transform: scale(1.8) rotate(0.01deg);
    transform: scale(1.8) rotate(0.01deg); */
}
.red-banner-img {
    display: block;
    width: 100%;
}
.red-banner-caption {
    font-family: 'Times New Roman', sans-serif;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5rem;
    transform: translate(-50%, -50%);
    /* filter: brightness(100%) */
}
/* RED BANNER END */

/* BANNER  */
.banner {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        
        rgba(52, 62, 86, 0.7),
        rgba(0, 0, 0, 1)
      ), 
      url(/static/media/HomeS1.2e85a766.jpg);

    background-size: cover;
    height: 100vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner:hover {
    /* -webkit-transform: scale(1.8) rotate(0.01deg);
    transform: scale(1.8) rotate(0.01deg); */
}
.banner-img {
    display: block;
    width: 100%;
}
.banner-caption {
    font-family: 'Times New Roman', sans-serif;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 5rem;
    transform: translate(-50%, -50%);
    /* filter: brightness(100%) */
}
.margin-0 {
    margin: 0 !important;
}
/* BANNER */
/* OVERLAY */
.overlay-background {
    height: 12vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 0; /* Sit on top */
    left: 0;
    top: 0;
    /* background-color: rgb(0,0,0, 0.45);  */
    background-color: rgba(50,50,50, 0.98); 
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s;
    opacity: 0.7; 
    -webkit-filter: blur(1px); 
            filter: blur(1px)
}
.overlay {
    height: 12vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    /* background-color: rgb(0,0,0, 0.45);  */
    background-color: rgba(50,50,50, 0.98); 
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s;
    opacity: 0.7; 
}
.overlay-content {
    position: relative;
    /* top: 25%;  */
    width: 100vw; /* 100% width */
    text-align: center; /* Centered text/links */
    margin: 3vh 0%;
}
.header-logo {
    width: 4vw;
    height: 7vh;
    transition: 1s ease;
    /* transition-delay: 1s; */
    /* transition-delay: 3s; */
    /* -webkit-transform: rotate(270deg);
    -webkit-animation-duration: 2s; */
}
.header-logo-rotated {
    width: 4vw;
    height: 7vh;
    transform: rotate(270deg);
    transition: 1s ease;
    transition-delay: 1s;
    /* transition-delay: 3s; 
    -webkit-transform: rotate(270deg);
    animation-duration: 3s;
    -webkit-animation-duration: 2s; */
}
/* OVERLAY */
/* BOTTOM RIGHT OVERLAY */
.bottom-overlay {
    /* height: 12vh; */
    /* width: 100vw; */
    /* margin-right: 2vw; */
    /* margin-bottom: 4vh; */
    padding: 2vw 3vh;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    right: 0;
    bottom: 0;
    /* background-color: rgb(0,0,0, 0.3);  */
    /* background-color: rgba(100,0,0, 1);  */
    overflow-x: hidden; /* Disable horizontal scroll */
    overflow-y: hidden;
    transition: 0.5s; 
    /* border-radius: 50%;
    height: 5vh;
    width: 5vh; */
}
.bottom-overlay-conent {
    position: relative;
    /* top: 25%;  */
    width: 100%; /* 100% width */
    text-align: center; /* Centered text/links */
    /* margin-bottom: 2vh; */
    /* margin-right: 5%; */
}
.bottom-overlay-icon {
    position: relative;
    /* transform: translateY(-1vh); */
    transition: 2s all;
    text-align: center;
    margin-bottom: 0.5vh;
    /* font-size: 3em; */
    /* width: 2vh;
    height: 2vh; */
}
.icon-three-em {
    font-size: 3em !important;
}
/* BOTTOM RIGHT OVERLAY */

/* PROJECT */
.project-main-font {
    font-size: 1.88em;
}
/* PROJECT */
.black {
    color: black !important;
}
.background-black {
    background-color: black !important;
}
.white-link {
    color: white !important;
}
.white-link:visited {
    color: white !important;
}
.white-link:hover {
    color: white !important;
}
.white-link:active {
    color: white !important;
}

/* CAROUSEL */
.epyco-carousel-image {
    height: 100vh;
    width: 100vw;
    -webkit-filter: brightness(0.35);
            filter: brightness(0.35);
}
.epyco-carousel-image-fading {
    height: 100vh;
    width: 100vw;
    -webkit-filter: contrast(0.4);
            filter: contrast(0.4);
    /* background: linear-gradient(to bottom, rgba(0,100,20, 0.9), rgba(160,20,20, 1.0)); */
}
.banner-carousel-1 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url(/static/media/F1.d058763d.webp);

    background-size: contain;
    background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-2 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url(/static/media/F2.5e4f631e.webp);

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-3 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url(/static/media/F3.bbf57f5c.webp);

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-4 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
         rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url(/static/media/F4.d7424e41.webp);

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
.banner-carousel-5 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 5%;
    background-image: linear-gradient(
        rgba(50,50,50, 0.15) 40%,
        rgba(150, 150, 150, 0.5) 75%, rgba(255,255,255,0.9) 95%,
        rgba(255,255,255, 1)
      ), 
      url(/static/media/F5.fc873cbf.webp);

      background-size: contain;
      background-repeat: round;
    height: 110vh;
    padding: 60px;
    text-align: center;
    color: white;
    font-size: 30px;
    /* opacity: 0.9; */
    margin-bottom: -4px;
}
/* CAROUSEL ENDS */
.hr-mw {
    max-width: 80vw !important;
    margin-left: auto!important;
    margin-right: auto!important;
}
.hr-margin {
    margin: 2vh 0px !important;
}
.footer-padding {
    padding: 2.9em 0em !important;
}
.grid-shadow {
    box-shadow: -1px 0 0 0 rgba(255,255,255,0.5) !important;
}
.no-grid-shadow {
    box-shadow: -1px 0 0 0 rgba(255,255,255,0) !important;;
}
/* Footer Min-Max */
.left-column {

}
.middle-column {
    text-align: center !important;
}
.right-column {
    text-align: left !important;
    padding-left: 9vw !important;
}
.footer-header {
    text-align: left;
    font-size: 1.371429rem; 
    width: 91%
}
.width-100 {
    width: 100vw !important;
}
.mobile-icon {
    text-align: left;
    padding: 0% 4%;
}

/* Footer Min-Max Ends*/
/* Messenger Playground */
/* .iframe {
    margin: 19vh 2.2vh !important;
} */
/* Messenger Playground Ends*/

@media only screen and (max-width: 760px) {
    p {
        font-size:  1.05em !important;
    }
    li {
        font-size: 0.95em !important;
    }
    h1, h2, h3, h4 {
        font-size: 1.30em !important;
    }
    .header-logo {
        width: 7vw;
        height: 6vh;
    }
    .banner-carousel-1, .banner-carousel-2, .banner-carousel-3, .banner-carousel-4, .banner-carousel-5 {
        background-size: cover !important;
        height: 60vh;
    }
    .portrait-main-branch {
        width: 45vw;
        height: 45vw;
    }
    .home-project-image {
        width: 67vw;
        height: 67vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        text-align: center;
    }
    .footer-header {
        text-align: center;
    }
    .footer-list { 
        text-align: center !important;
    }
    .banner-caption {
        transform: translate(-50%, -160%);
    }
}
@media only screen and (min-width: 761px) and (max-width: 1223px) and (orientation: landscape) {
    p {
        font-size:  1em !important;
    }
    li {
        font-size:  0.85em !important;
    }
    h1, h2, h3 {
        font-size: 1.45em !important;
    }
    h4 {
        font-size: 0.85em !important;
    }

}
@media only screen and (min-width: 1225px) and (max-width: 1600px) {
    p {
        font-size:  1.15em !important;
    }
    h1 {
        font-size: 1.50em !important;
    }
    h2 {
        font-size: 1.3em !important;
    }
    h3 {
        font-size: 1.25em !important;
    }
    h4 {
        font-size: 1.10em !important;
    }
    span {
        font-size: 0.90em !important;
    }
    /* .header-logo {
        width: 7vw;
        height: 6vh;
    }
    .banner-carousel-1, .banner-carousel-2, .banner-carousel-3, .banner-carousel-4, .banner-carousel-5 {
        background-size: cover !important;
        height: 60vh;
    }
    .portrait-main-branch {
        width: 45vw;
        height: 45vw;
    }
    .home-project-image {
        width: 67vw;
        height: 67vw;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .footer {
        text-align: center;
    }
    .footer-header {
        text-align: center;
    }
    .footer-list { 
        text-align: center !important;
    }
    .banner-caption {
        transform: translate(-50%, -160%);
    } */
}
.divImgAvatar {
    border-radius: 50%;
    /* background-color: black; */
    margin-top: 5%;
}
.margin-0 {
  margin: 0 !important;
}
.cart-image {
  height: 10vh;
  width: 5vw;
}

.margin-l-r-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.cart-side-h3 {
  line-height: 4vh;
}

.imgAvatar {
    margin-right: .25em;
    display: inline-block;
    width: 5em;
    height: 5em;
    border-radius: 500rem
}

.amountButton {
    margin: 0% 4% !important;
}

.cardBorder { 
    padding: 2% 0% 2% 0%; 
    border: 0.5px solid #888888;
    border-radius: 5%;
    /* box-shadow: 5px 10px 18px #888888; */
    box-shadow: 0px 2px 2px #888888;
    transition: transform .1.5s;
    /* background-image: url("../logo.svg"); */
    background-repeat: no-repeat;
    /* transform-style: preserve-3d; */
}

.cardBorder:hover {
    transform: scale(1.05);
    /* transform: rotateY(180deg); */
}

.margin-bottom-75 {
  margin-bottom: 55px;
}

.hidden.menu {
    display: none;
  }

  .masthead.segment {
    min-height: 700px;
    padding: 1em 0em;
  }
  .masthead .logo.item img {
    margin-right: 1em;
  }
  .masthead .ui.menu .ui.button {
    margin-left: 0.5em;
  }
  .masthead h1.ui.header {
    margin-top: 3em;
    margin-bottom: 0em;
    font-size: 4em;
    font-weight: normal;
  }
  .masthead h2 {
    font-size: 1.7em;
    font-weight: normal;
  }

  .ui.vertical.stripe {
    padding: 8em 0em;
  }
  .ui.vertical.stripe h3 {
    font-size: 2em;
  }
  .ui.vertical.stripe .button + h3,
  .ui.vertical.stripe p + h3 {
    margin-top: 3em;
  }
  .ui.vertical.stripe .floated.image {
    clear: both;
  }
  .ui.vertical.stripe p {
    font-size: 1.33em;
  }
  .ui.vertical.stripe .horizontal.divider {
    margin: 3em 0em;
  }

  .quote.stripe.segment {
    padding: 0em;
  }
  .quote.stripe.segment .grid .column {
    padding-top: 5em;
    padding-bottom: 5em;
  }

  .footer.segment {
    padding: 5em 0em;
  }

  .secondary.pointing.menu .toc.item {
    display: none;
  }

  @media only screen and (max-width: 700px) {
    .hidden-mobile {
      display: block;
  }
  
    .ui.fixed.menu {
      display: none !important;
    }
    .secondary.pointing.menu .item,
    .secondary.pointing.menu .menu {
      display: none;
    }
    .secondary.pointing.menu .toc.item {
      display: block;
    }
    .masthead.segment {
      min-height: 350px;
    }
    .masthead h1.ui.header {
      font-size: 2em;
      margin-top: 1.5em;
    }
    .masthead h2 {
      margin-top: 0.5em;
      font-size: 1.5em;
    }
  }
.module {
    background:     
    linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url(/static/media/breakfast.c43cbf1e.jpg);
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: cover;
    /* margin-bottom: 60px; */
  }
  .module > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background: inherit;
    background-attachment: fixed;
    overflow: hidden;
  }
  .module > header::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 200%;
    height: 200%;
    background: inherit;
    background-attachment: fixed;
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
  .module > header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25)
  }
  .module > header > h1 {
    margin: 0 0 0 3vh;
    color: white;
    position: relative;
    z-index: 0;
  }
  
  * {
    box-sizing: border-box;
  }
.title-h3 {
    font-size: xx-large;
    color: white;
    background: url(/static/media/breakfast.c43cbf1e.jpg)
}
.main-container {
    min-height: 70vh;
}
.image-div {
    padding: 5%;
}
.small-image {
    height: 40vh;
}
.enlarged-image {

}
.product-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}
.product-detail-container {
    margin: 5%;
    background: aliceblue;
    border-radius: 1vh;
    padding: 1%;
}
.product-detail-image {
    height: 25vh;
    width: 25vh;
    transition: transform 0.4s;
}
.product-detail-image:hover {
    transform: scale(1.2) rotate(0.01deg);
}
.products {
    /* border: 1px solid; */
    background-color: white;
    background-image: url(/static/media/dark-wood.e3577ddd.png);
    margin-bottom: 5em;
    border-radius: 1vh;
}

.product-image {
    transition: transform 0.4s;
}

.product-image:hover {
    transform: scale(1.2) rotate(0.01deg);
}

.huge {
    font-size: 2rem;
    color: #162E2C;
    font-weight: bolder;
}

.background {
    background-color: #2E2016;
    /* background-color: #00325e; */
    /* background-color: white; */
    /* background-image: linear-gradient(
        rgba(26, 12, 2, 0.5),
        rgba(0, 0, 0, 0.5)
      ), url('../images/dark-wood.png'); */
    background-image: url(/static/media/dark-wood.e3577ddd.png);

}

.hidden-mobile {
    display: block;
}

@media only screen and (max-width: 1000px) {
    .hidden-mobile {
        display: none !important;
    }
}
/* -- code to use Gotham font-family in theme -- */
/* @font-face {
    font-family: 'Gotham';
    src: 	url('{{ "Gotham-Bold.eot" | asset_url }}');
    src: 	url('{{ "Gotham-Bold.eot" | asset_url }}#iefix') format("embedded-opentype"),
            url('{{ "GothamBold.woff" | asset_url }}') format("woff"),
      url('{{ "GothamBold.woff2" | asset_url }}') format("woff2"),
           url('{{ "GothamBold.svg" | asset_url }}#GothamBold') format("svg");
          font-weight: bold;
          font-style: normal;
  } */
  /* - end - */
  /* -- Pipeline override custom font: Headings -- */
.title,h1,.h1,h2,.h2,h3,.h3,h4.home__subtitle,h5.home--uppercase,.sky-pilot-access-denied h5,.sky-pilot-access-denied .h5,h4,.h4,h5,.h5,h6,.h6,.header-logo,.home__title,.title--flex,.product-form label,.password__hero {
    font-family: 'Gotham', sans-serif !important;
 }
 /* - end - */
 /* -- Pipeline override custom font: body font -- */
.body, .h1--body, .h2--body, .h3--body, .h4--body, .h5--body, .h6--body, p {
    font-family: 'Gotham', sans-serif !important;
}
/* - end - */
/* -- Pipeline override custom font: accent - Subheadings and buttons -- */
.accent, .uppercase, .nav--mobile .main-menu li a.nav-link, .nav--desktop .main-menu>li>a, .nav--desktop .grandparent .parent .child a, .nav--desktop .grandparent>.main-menu-dropdown>ul>.parent>a, .nav--desktop .grandparent>.main-menu-dropdown>ul>.child>a, .nav--desktop .main-menu>.parent>.main-menu-dropdown, .footer-social, .footer-payment, .sticker, .share__link, .sidebar__item a, .selector-wrapper label, .quantity-selector__wrapper label, .blog__tag, .item__vendor, .product__add__message, .h1--accent, .h2--accent, .h3--accent, .h4--accent, .h5--accent, .home__subtitle, .h6--accent, .footer-secondary, .footer-quicklinks, .breadcrumb{
    font-family: 'Gotham', sans-serif !important;
}
/* - end - */
/* The Modal (background) */
.modal {
    /* display: none;  */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  .round-border {
    border-radius: 4vh;
  }
  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    /* margin: 15% auto;      */
    padding: 5vh;
    border: 1px solid #888;
    width: 30%; 
    height: 70%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: black; */
    border-radius: 4vh;
  }

  .modal-field {
    margin: 0 0 1em;
  }

  .modal-close {
    position: absolute;
    right: 0;
    margin: 3vh 3vh 0vh 0vh !important;
    top: 0;
  }

  .modal-buttons {
   margin-top: 3vh;
   text-align: center; 
  }

  .modal-header {
      margin-bottom: 5vh;
      text-align: center;
      font-size: large;
  }

  .modal-logo {
      width: 10vh;
      height: 10vh;
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 1vh;
      margin-bottom: 1em;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 700px) {
    .modal-content {
      font-size: small;
      width: 85%;
    }

    .modal-logo {
      width: 7vh;
      height: 7vh;
    }

    .modal-close {
      position: absolute;
      right: 0;
      margin: 2vh 2vh 0vh 0vh !important;
      top: 0;
      font-size: smaller;
      width: 5vh;
      height: 4vh;
      padding: 0 !important;
    }
  }
/* Bottom Overlay Spring */
.simple-trans-main {
    height: 100vh;
}
.simple-trans-main > div {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 800;
    font-size: 25em;
    will-change: transform, opacity;
    text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
  }
  
/* Bottom Overlay Spring END*/
/* Keyframes */
/* .keyframes {
    height: 100vh;
}
.keyframes > div {
    height: 100vh;
} */
.bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-position: center;
    will-change: opacity;
  }
/* Keyframes END*/
