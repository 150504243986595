.thick {
    font-weight: bolder !important;
}
.cursor-pointer {
    cursor: pointer;
}

.font-bolder {
    font-weight: bolder;
}

.font-small { 
    font-size: small;
}
.padding-2 {
    padding: 2%;
}
.padding-5 {
    padding: 5% !important;
}
.margin-5 {
    margin: 5%;
}
.margin-top-2 {
    margin-top: 2%;
}
.margin-bottom-2 {
    margin-bottom: 2% !important;
}

.carousel-img {
    height: 50vh;
    width: 100vw;
}
.carousel-caption-div {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10vh;
    background: rgba(0, 0, 0, 0.65)
}
.carousel-caption-text {
    margin: 0 0 2vh 3vh;
    bottom: 0;
    left: 0;
    position: absolute;
    color: white;
    /* position: relative; */
    line-height: 10vh;
    z-index: 0;
}
.text-center {
    text-align: center;
}
.side-shopping-cart-title {
    margin-top: 1vh;
    cursor: pointer;
}

.uppercase {
    text-transform: uppercase;
}