.module {
    background:     
    linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("../images/breakfast.jpg");
    background-attachment: fixed;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-size: cover;
    /* margin-bottom: 60px; */
  }
  .module > header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 10px;
    background: inherit;
    background-attachment: fixed;
    overflow: hidden;
  }
  .module > header::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    width: 200%;
    height: 200%;
    background: inherit;
    background-attachment: fixed;
    -webkit-filter: blur(4px);
    filter: blur(4px);
  }
  .module > header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25)
  }
  .module > header > h1 {
    margin: 0 0 0 3vh;
    color: white;
    position: relative;
    z-index: 0;
  }
  
  * {
    box-sizing: border-box;
  }